'use client';

import { Column } from '@kamona/components';

export default function SignInLayout({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  return <Column className="min-h-svh">{children}</Column>;
}
